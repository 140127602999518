import React from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import MyVerticallyCenteredModal from "../Touch_Modal/MyVerticallyCenteredModal";
import Touch_Modal from "../Touch_Modal/Touch_Modal";
import { Link } from "react-router-dom";
import BlManLogo from "../assets/betterlogics-removebg-preview.png";
import { useNavigate } from "react-router-dom";

function Header() {
  const history = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary mainbl bg-dark py-3"
      >
        <Container className="">
          <Navbar.Brand className="text-white lOgoUpper" onClick={() => (
              history("/"),
              window.scrollTo({ top: 0, behavior: "instant" })
            )}>
              <img src={BlManLogo} alt="#" className="bl_LogO det2" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="">
            <Nav className="lnkUpper">
              <>
                <Nav.Link
                  className="nvlis"
                  onClick={() => (
                    history("/"),
                    window.scrollTo({ top: 0, behavior: "instant" })
                  )}
                >
                  Home
                </Nav.Link>
              </>

              <>
                <Nav.Link
                  className="nvlis"
                  onClick={() => (
                    history("/Portfolio"),
                    window.scrollTo({ top: 0, behavior: "instant" })
                  )}
                >
                  Portfolio
                </Nav.Link>
              </>
              {/* <Nav.Link className="nvlis" href="#">
                Career
              </Nav.Link> */}
              <Nav.Link className="nvlis" href="#">
                About Us
              </Nav.Link>
              <Nav.Link className="nvlis" href="https://blocksolution.netlify.app/">
                Get Audit
              </Nav.Link>
            </Nav>
            <Nav className="">
              <Nav.Link
                onClick={() => setModalShow(true)}
                className="nvlis d-flex align-items-center"
                href="#"
              >
                {" "}
                <span className="arrowspn"></span> Get in touch
              </Nav.Link>
              <Touch_Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
