import React from 'react'
import "./TechnologyStack_type.css"
import cto from '../assets/cto.jpeg'
import ceo from '../assets/ceo.jpeg'
import dev from '../assets/nawazish.png'
import dev2 from '../assets/dev2.png'
import dev3 from '../assets/dev3.png'





// import ceo from "./assets/ceo.jpg"
// import cto  from "./assets/cto.jpg"
// import dev from "./assets/dev.jpg"

function TechnologyStack_type() {
  return (
    <div>
        <div className="row justify-content-evenly " style={{marginTop:"4rem"}}>

        <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=034" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Ethereum Chain</h5>
        </div>
      </div>

      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=034" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>AVAX Chain</h5>
        </div>
      </div>
      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://tokenlogo.xyz/assets/chain/base.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>BASE Chain</h5>
        </div>
      </div>

      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=034" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>ARBITRUM Chain</h5>
        </div>
      </div>
      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cryptologos.cc/logos/polygon-matic-logo.svg?v=034" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>POLYGON(MATIC) Chain</h5>
        </div>
      </div>
      <div className="col-lg-5">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/Binance-Smart-Chain-0.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Binance Chain</h5>
        </div>
      </div>

      <div className="col-lg-5 meg2">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cryptologos.cc/logos/tron-trx-logo.svg?v=034" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>TRON CHAIN</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/polkadot-new-dot-logo-3.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Polkadot</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cryptologos.cc/logos/toncoin-ton-logo.svg?v=034" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>TON BLOCKCHAIN</h5>
        </div>
      </div>

      <div className="col-lg-5 meg">
        <div className='d-flex gap-3 align-items-center itemUppre'>
            <div className='logoBx'>
                <img src="https://cdn.phenomenonstudio.com/wp-content/uploads/2023/05/solana-sol-logo-1.svg" alt="#" className='itemLogo' />
            </div>
            <h5 className='itemName mb-0'>Solana</h5>
        </div>
      </div>
      </div>
      {/* <div className="container-fluid mt-5">
        <div className="container">

          <div className="CEOdiv ">
            <div className="team-member ">
              <h1 className="team_main">Our Team </h1>

              <div className=" imgg hide-up mt-5" style={{ opacity: 1 }}>
                  <img
                    className=" circle resp"
                    src="https://codify.pk/public/assets/images/team/team-shape.png"
                    alt=""
                    style={{ position: "relative" }}
                  />
                  <div className="">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/muhammad-rehman-285522215/"
                    >
                      {" "}
                      <img
                        className="img"
                        src={ceo}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          marginTop: "",
                          position: "relative",
                        }}
                      />
                    </a>
                  </div>
                </div>

              <div className="names">
                <h4 className="Headingh1">Abdul Rehman </h4>
                <h6 class="leads_n hide-up"> Founder &amp; CEO </h6>
              </div>
            </div>
          </div>

          <div className="row  mt-4">
            <div className="col-lg-6 mt-5">
              <div className="JunierDep">
                <div className=" imgg hide-up" style={{ opacity: 1 }}>
                  <img
                    className=" circle resp"
                    src="https://codify.pk/public/assets/images/team/team-shape.png"
                    alt=""
                    style={{ position: "relative" }}
                  />
                  <div className="">
                    <a
                      target="_blank"
                      href=""
                    >
                      {" "}
                      <img
                        className="img"
                        src={cto}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          marginTop: "",
                          position: "relative",
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className="names">
                  <h4 className="Headingh1">Muhammad Tayyab</h4>
                  <h6 class="leads_n hide-up">
                   CTO  &amp; Full Stack Developer
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-5">
              <div className="JunierDep">
                <div className=" imgg hide-up" style={{ opacity: 1 }}>
                  <img
                    className=" circle resp"
                    src="https://codify.pk/public/assets/images/team/team-shape.png"
                    alt=""
                    style={{ position: "relative" }}
                  />
                  <div className="">
                    <a
                      target="_blank"
                      href=""
                    >
                      {" "}
                      <img
                        className="img"
                        src={dev}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          marginTop: "rem",
                          position: "relative",
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className="names">
                  <h4 className="Headingh1">Nawazish Ali</h4>
                  <h6 class="leads_n hide-up">
                    Frontend Developer &amp; Team Lead
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row  mt-4">
            <div className="col-lg-6 mt-5">
              <div className="JunierDep">
                <div className=" imgg hide-up" style={{ opacity: 1 }}>
                  <img
                    className=" circle resp"
                    src="https://codify.pk/public/assets/images/team/team-shape.png"
                    alt=""
                    style={{ position: "relative" }}
                  />
                  <div className="">
                    <a
                      target="_blank"
                      href=""
                    >
                      {" "}
                      <img
                        className="img"
                        src={dev3}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          marginTop: "",
                          position: "relative",
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className="names">
                  <h4 className="Headingh1">Azan Ahmad</h4>
                  <h6 class="leads_n hide-up">
                   Junior Frontend Developer
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-5">
              <div className="JunierDep">
                <div className=" imgg hide-up" style={{ opacity: 1 }}>
                  <img
                    className=" circle resp"
                    src="https://codify.pk/public/assets/images/team/team-shape.png"
                    alt=""
                    style={{ position: "relative" }}
                  />
                  <div className="">
                    <a
                      target="_blank"
                      href=""
                    >
                      {" "}
                      <img
                        className="img"
                        src={dev2}
                        alt=""
                        style={{
                          borderRadius: "50%",
                          marginTop: "rem",
                          position: "relative",
                        }}
                      />
                    </a>
                  </div>
                </div>
                <div className="names">
                  <h4 className="Headingh1">Mahad Naveed</h4>
                  <h6 class="leads_n hide-up">
                  Junior Frontend Developer
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
    
  )
}

export default TechnologyStack_type
